import React, { useEffect, useState } from "react";

import { Button, FormControlLabel, Switch } from "@material-ui/core";
import { Link } from "gatsby";
import { isEmpty } from "lodash";
import moment from "moment";

import DashboardGrid from "~/components/Appointments/DashboardGrid";
import PrivateRoute from "~/components/Authentication/PrivateRoute";
import LoadingError from "~/components/Loaders/LoadingError";
import SuperadminToggle from "~/components/Users/SuperadminToggle";
import api from "~/utils/api/api";
import appointmentService from "~/utils/api/v1/appointmentService";
import userService from "~/utils/api/v1/userService";
import Auth from "~/utils/auth/auth";
import { calculateAge } from "~/utils/dates/formatDate";
import Appointment from "~/utils/interfaces/Appointment";
import { User, baseUser } from "~/utils/interfaces/User";

const auth = new Auth();

interface Props {
  id: string;
}

const IndexPage = ({ id }: Props): JSX.Element => {
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Object>({});
  const [user, setUser] = useState<User>(baseUser);
  const [appointments, setAppointments] = useState<Appointment[]>([]);
  const [nextRequest, setNextRequest] = useState<string | null>(null);
  const [prevRequest, setPrevRequest] = useState<string | null>(null);
  const [totalRows, setTotalRows] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [sms, setSms] = useState<boolean>(true);
  const [fasting, setFasting] = useState<boolean>(false);

  const isSuperAdmin1 = auth.isSuperadmin1();
  const fetchUserAndAppointments = async (): Promise<void> => {
    setLoading(true);
    try {
      const userRequest = await appointmentService.fetchUser(id);
      const appointmentRequest = await userService.fetchUserAppointments(id);
      setUser(userRequest.data.data);
      setAppointments(appointmentRequest.data.results);
      setTotalRows(appointmentRequest.data.count);
      setNextRequest(appointmentRequest.data.next.replace("http://", "https://"));
      setPrevRequest(appointmentRequest.data.previous.replace("http://", "https://"));
    } catch (err) {
      setError(err);
    }
    setLoading(false);
  };

  const fetchNewPage = async (action: "next" | "previous"): Promise<void> => {
    setLoading(true);
    try {
      const request = await api.request({
        method: "GET",
        url: action === "next" ? nextRequest : prevRequest,
      });
      setAppointments(request.data.results);
      setTotalRows(request.data.count);
      setNextRequest(request.data.next.replace("http://", "https://"));
      setPrevRequest(request.data.previous.replace("http://", "https://"));
    } catch (err) {
      setError(err);
    }
    setLoading(false);
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    const action = currentPage > newPage ? "previous" : "next";
    fetchNewPage(action);
    setCurrentPage(newPage);
  };

  useEffect(() => {
    fetchUserAndAppointments();
  }, []);

  return (
    <PrivateRoute>
      <LoadingError
        loading={loading}
        error={error}
      />
      {!loading && isEmpty(error) && (
        <>
          <div className="text-center p-16">
            <div className="bg-gray-200 rounded-lg p-4 inline-block text-left">
              <div className="flex justify-between mb-4">
                <h3 className="mr-8">Usuario</h3>
                <div className="flex justify-center items-center">
                  {isSuperAdmin1 && <SuperadminToggle userId={user.id} />}
                </div>
              </div>
              <p className="mb-2">
                <span className="font-bold">Primer Nombre: </span> {user.first_name}
              </p>
              <p className="mb-2">
                <span className="font-bold">Segundo Nombre: </span> {user.second_name}
              </p>
              <p className="mb-2">
                <span className="font-bold">Primer Apellido: </span> {user.last_name}
              </p>
              <p className="mb-2">
                <span className="font-bold">Segundo Apellido: </span> {user.second_last_name}
              </p>
              <p className="mb-2">
                {(user.document_type === "ci" || user.document_type === undefined) && <span className="font-bold">RUT: </span>}
                {user.document_type === "passport" && <span className="font-bold">Pasaporte: </span>}
                {user.document_number}
              </p>
              <p className="mb-2">
                <span className="font-bold">Sexo: </span> {user.gender === "male" ? "Masculino" : "Femenino"}
              </p>
              <p className="mb-2">
                <span className="font-bold">Teléfono: </span> {user.phone}
              </p>
              <p className="mb-2">
                <span className="font-bold">Email: </span> {user.email}
              </p>
              <p className="mb-2">
                <span className="font-bold">Edad: </span> {calculateAge(user.date_of_birth)} años
              </p>
              <p className="mb-2">
                <span className="font-bold">Fecha de nacimiento: </span> {moment.utc(user.date_of_birth).format("DD [de] MMMM [de] YYYY")}
              </p>
              <div className="flex justify-end mt-10">
                <Link to="/users">
                  <Button
                    variant="text"
                    color="primary"
                  >
                    Volver
                  </Button>
                </Link>
                <Link to={`/users/${user.id}/edit`}>
                  <Button
                    variant="outlined"
                    color="primary"
                  >
                    Editar
                  </Button>
                </Link>
              </div>
            </div>
          </div>
          <div className="flex justify-around my-8">
            <FormControlLabel
              label="Ayuno"
              control={
                <Switch
                  color="primary"
                  checked={fasting}
                  onChange={(e) => {
                    setFasting(e.target.checked);
                  }}
                />
              }
            />
            <FormControlLabel
              label="SMS"
              control={
                <Switch
                  color="primary"
                  checked={sms}
                  onChange={(e) => {
                    setSms(e.target.checked);
                  }}
                />
              }
            />
          </div>
          <DashboardGrid
            appointments={appointments}
            sms={sms}
            fasting={fasting}
            totalRows={totalRows}
            currentPage={currentPage}
            handleChangePage={handleChangePage}
          />
        </>
      )}
    </PrivateRoute>
  );
};

export default IndexPage;
